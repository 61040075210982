export default [
  {
    header: 'Settings',
    roles: {
      admin: ['read', 'write'],
      consultant: ['read', 'write'],
      supervisor: ['read', 'write'],
      client: ['read', 'write'],
    },
  },
  {
    title: 'Account Settings',
    route: 'pages-account-setting',
    icon: 'SettingsIcon',
  },
  {
    title: 'Sync Status',
    route: 'pages-sync-status',
    icon: 'UploadCloudIcon',
  },
]
