export default [
  {
    header: 'Continuous Improvement',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      supervisor: ["read", "write"],
      client: ["read", "write"],
    }
  },
  {
    title: 'Improvements',
    route: 'apps-todo',
    icon: 'CheckCircleIcon',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      supervisor: ["read", "write"],
      client: ["read", "write"],
    }
  },
  {
    title: 'AI Assistance',
    route: 'habit-assistance-list',
    icon: 'ListIcon',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      supervisor: ["read", "write"],
      client: ["read", "write"],
    }
  },
  {
    title: 'Problem Solving',
    route: 'apps-problem-solving',
    icon: 'PenToolIcon',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      supervisor: ["read", "write"],
      client: ["read", "write"],
    }
  },
]