export default [
  {
    header: 'Extra',
    roles: {
      admin: ['read', 'write'],
    },
  },
  {
    title: 'Access Control',
    route: 'access-control',
    icon: 'ShieldIcon',
    roles: {
      admin: ['read', 'write'],
    },
  },
  {
    title: 'Support',
    href: 'https://addval.cl/soporte',
    icon: 'LifeBuoyIcon',
    roles: {
      admin: ['read', 'write'],
    },
  },
  {
    title: 'Legal',
    icon: 'FileTextIcon',
    roles: {
      admin: ['read', 'write'],
    },
  },
]
