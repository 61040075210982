<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded" style="margin-top: .7rem">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <!-- <b-img :src="appLogoToShow" alt="logo" /> -->
                <b-img :src="appLogoToShow" alt="logo" style="width: 2rem; height: 2rem;"/>
              </span>
              <h2 class="brand-text">
                {{ appNameToShow }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";
import store from '@/store/index'
import endpoints from '@/libs/endpoints'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const userData = store.state.userStore.userData;
    const { role } = userData;
    const filterAndMap = (item) => {
      if (item.roles) {
        const roles = Object.keys(item.roles);
        if (item.hasOwnProperty("children")) {
          const haveAccessToChilds = item?.children?.filter((child) => {
            return child.roles.hasOwnProperty(role);
          });

          if (!roles.includes(role) || !haveAccessToChilds.length) return false;
          else return { ...item, children: haveAccessToChilds };
        } else if (!roles.includes(role)) return false;
      }
      return item;
    };
    const filteredNavItems = navMenuItems?.filter(filterAndMap).map(filterAndMap);

    // Personalize vertical nav menu for different clients
    const clientData = JSON.parse(localStorage.getItem("clientData") || '{}')
    const commitmentFunctionality = clientData.commitment_functionality
    const pcTeamDefaultView = clientData.default_view_pc_team
    const excludePCFunctionality = clientData.exclude_pc_functionality
    const excludePCTeamModule = clientData.exclude_pc_team_module
    const celulosaAraucoView = clientData.celulosa_arauco_view
    const information_module = clientData.information_module
    const assistanceIA = clientData.assistanceIA_functionality

    let excludeDashboards = false
    const inDemo = endpoints.app_id === process.env.VUE_APP_APP_ID_DEMO

    // Function for cases where there are children inside the children
    const removeChildrenFromChildren = (children, route) => {
      for (let i = 0; i < children.length; i++) {
        const item = children[i];
        if (item.children) {
          for (let j = 0; j < item.children.length; j++) {
            const child = item.children[j]
            if (child.route === route) {
              item.children.splice(j, 1);
              j--
            }
          }
        }
      }
    }

    for (let i = 0; i < filteredNavItems.length; i++) {
      const e = filteredNavItems[i];

      // TODO: Improve this logic because it relies on header names "PC Dashboards" and "DD Dashboards" to not change over time

      //  Daily Dialogue children are shown only if the role is consultant, and only for information_module --> true
      if ((role !== 'consultant' || !information_module) &&  e.title === 'Daily Dialogue') {
        delete e.children
        e.route = 'habit-meeting-new'
      }

      // Exclude PC Dashboards and/or Daily Dialogue section/s if applicable
      if (excludePCFunctionality || commitmentFunctionality || celulosaAraucoView) {
        if ((excludePCFunctionality && e.title === "PC Dashboards") || (commitmentFunctionality || celulosaAraucoView) && e.header === "Daily Dialogue") {
          excludeDashboards = true
        } else if (excludeDashboards && e.header) {
          excludeDashboards = false
        }
        if (excludeDashboards) {
          filteredNavItems.splice(i, 1)
          i--
          continue
        }
      }

      // Personalize or exclude item based on route
      if (e.children) {

        if (!commitmentFunctionality && !celulosaAraucoView && !inDemo) {
          removeChildrenFromChildren(e.children, 'habit-commitment-dashboard')
        }
        if(commitmentFunctionality) {
          removeChildrenFromChildren(e.children, 'habit-daily-dashboard')
          removeChildrenFromChildren(e.children, 'habit-recognition-dashboard')
          removeChildrenFromChildren(e.children, 'habit-improvement-dashboard')
          removeChildrenFromChildren(e.children, 'habit-happiness-dashboard')
          removeChildrenFromChildren(e.children, 'habit-happiness-measure-of-happiness')
        }

        for (let j = 0; j < e.children.length; j++) {
          const child = e.children[j];
          switch (child.route) {
            case 'organization-process-list':
              filteredNavItems[i].children[j].title = commitmentFunctionality ? 'Confirmations List' : 'List'
              break;
            case 'organization-worker-list':
              if (commitmentFunctionality && role !== 'consultant') {
                filteredNavItems.splice(i, 1)
                i--
              }
              break;
            case 'habit-adherence-dashboard':
              if (commitmentFunctionality && role !== 'consultant') {
                filteredNavItems[i].children.splice(j, 1)
                j--
              }
              break;
            case 'organization-pending-confirmations':
              if (commitmentFunctionality) {
                filteredNavItems[i].children.splice(j, 1)
                j--
              }
              break;
            case 'habit-meeting-new':
            case 'habit-meeting-communications':
              if (commitmentFunctionality || celulosaAraucoView) {
                filteredNavItems.splice(i, 1)
                i--
            }
            default:
              break;
          }
        }
      } else {
        switch (e.route) {
          case 'apps-pc-team':
            if (excludePCTeamModule) {
              filteredNavItems.splice(i, 1)
              i--
            }
            break;
          case 'apps-calendar':
            filteredNavItems[i].title = commitmentFunctionality ? 'Schedule' : 'Calendar'
            if (pcTeamDefaultView || excludePCFunctionality) {
              filteredNavItems.splice(i, 1)
              i--
            }
            break;
          case 'apps-todo':
            filteredNavItems[i].title = commitmentFunctionality ? 'Commitments' : 'Improvements'
            break;
          case 'habit-improvement-dashboard':
            filteredNavItems[i].title = commitmentFunctionality ? 'Commitments Dashboard' : 'Improvement Opportunities'
            break;
          case 'habit-meeting-new':
            if (commitmentFunctionality || celulosaAraucoView) {
              filteredNavItems.splice(i, 1)
              i--
            }
            break;
          case 'apps-problem-solving':
            if (!commitmentFunctionality) {
              filteredNavItems.splice(i, 1)
              i--
            }
            break;
          case 'habit-assistance-list':
          if (!assistanceIA) {
            filteredNavItems.splice(i, 1)
            i--
          }
          break;
          default:
            break;
        }
      }
    }

    store.commit('userStore/updatePermissions', filteredNavItems);
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    const appNameToShow = appName
    const appLogoToShow = appLogoImage

    return {
      navMenuItems: filteredNavItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      // appName,
      // appLogoImage,
      appNameToShow,
      appLogoToShow,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>