export default [{
    header: 'Process Confirmations',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      client: ["read", "write"],
      supervisor: ["read"]
    }
  },
  {
    title: 'PC Team',
    icon: 'UsersIcon',
    route: 'apps-pc-team',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      supervisor: ["read", "write"],
      client: ["read", "write"],
    },
    // children: [
    //   {
    //     title: 'PC Team',
    //     route: 'apps-pc-team',
    //     roles: {
    //       admin: ["read", "write"],
    //       consultant: ["read", "write"],
    //       supervisor: ["read", "write"],
    //       client: ["read", "write"],
        
    //     }
    //   },
    //   {
    //     title: 'Programming',
    //     route: 'apps-programming-team',
    //     roles: {
    //       admin: ["read", "write"],
    //       consultant: ["read", "write"],
    //       supervisor: ["read", "write"],
    //       client: ["read", "write"],
    //     }
    //   } 
    // ]
  },
  {
    title: 'Processes',
    icon: 'RepeatIcon',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      supervisor: ["read"],
      client: ["read", "write"],
    },
    children: [{
        title: 'List',
        route: 'organization-process-list',
        roles: {
          admin: ["read", "write"],
          consultant: ["read", "write"],
          client: ["read", "write"],
          supervisor: ["read"]
        },
      },
      {
        title: 'Pending Confir.',
        route: 'organization-pending-confirmations',
        roles: {
          admin: ["read", "write"],
          client: ["read", "write"],
          consultant: ["read", "write"],
          supervisor: ["read"]
        },
      },
    ],
  },
  {
    title: 'Confirmation Planning',
    icon: 'ClipboardIcon',
    route: 'organization-confirmation-planning-list',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      supervisor: ["read"],
      client: ["read", "write"],
    }
  },
  {
    title: 'PC Dashboards',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Compliance',
        icon: 'BarChartIcon',
        children: [{
          title: 'Global',
          icon: 'CircleIcon',
          route: 'habit-confirmation-dashboard',
        },
        {
          title: 'Daily',
          icon: 'CircleIcon',
          route: 'habit-daily-dashboard',
        }],
      },
      {
        title: 'Adherence',
        icon: 'UserCheckIcon',
        children: [{
          title: 'Processes',
          icon: 'CircleIcon',
          route: 'habit-adherence-dashboard',
        },
        {
          title: 'Behaviours',
          icon: 'CircleIcon',
          route: 'habit-behaviour-dashboard',
        },
        {
          title: 'Commitments',
          icon: 'CircleIcon',
          route: 'habit-commitment-dashboard'
        }
      ],
      },
    ]
  }
]