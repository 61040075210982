<template>
  <b-nav-item @click="skin = isDark ? 'light' : 'dark'">
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="flat-primary"
      v-b-tooltip.hover.v-primary.bottom="`${isDark ? $t('SwitchToLightMode') : $t('SwitchToDarkMode')}`"
      class="btn-icon rounded-circle ml-0"
    >
      <feather-icon
        size="19"
        :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
      />
    </b-button>
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BNavItem,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>
