export default [{
    header: 'Organization',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      client: ["read", "write"],
      supervisor: ["read"]
    }
  },
  {
    title: 'Calendar',
    route: 'apps-calendar',
    icon: 'CalendarIcon',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      supervisor: ["read", "write"],
      client: ["read", "write"],
    }
  },
  {
    title: 'Employees',
    icon: 'FilmIcon',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      client: ["read", "write"],
      supervisor: ["read"]
    },
    children: [{
        title: 'Users',
        route: 'organization-worker-list',
        roles: {
          admin: ["read", "write"],
          consultant: ["read", "write"],
          client: ["read", "write"],
          supervisor: ["read"]
        },
      },
      {
        title: 'Roles',
        route: 'organization-role-list',
        roles: {
          admin: ["read", "write"],
          client: ["read", "write"],
          consultant: ["read", "write"]
        },
      },
      {
        title: 'Locations',
        route: 'organization-location-list',
        roles: {
          admin: ["read", "write"],
          client: ["read", "write"],
          consultant: ["read", "write"]
        },
      },
      {
        title: 'Metadata',
        route: 'organization-metadata-list',
        roles: {
          admin: ["read", "write"],
          client: ["read", "write"],
          consultant: ["read", "write"]
        },
      }
    ],
  },
]