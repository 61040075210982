<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-md-flex d-none user-nav">
        <small class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </small>
        <span class="user-status text-primary" style="margin-top: .3rem">{{ $t(`userRoles.${userData.role}`) }}</span>
      </div>
      <div class="d-block justify-content-center align-center">
        <b-avatar
          size="35"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon
            v-if="!userData.fullName"
            icon="UserIcon"
            size="18"
          />
        </b-avatar>
      </div>
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      v-if="showChangeDemoButton"
      link-class="d-flex align-items-center"
      @click="changeDemo"
    >
      <feather-icon
        size="16"
        icon="RefreshCcwIcon"
        class="mr-50"
      />
      <span>{{ $t(`changeDemo`) }}</span>
    </b-dropdown-item> -->

    <!-- <b-dropdown-divider v-if="showChangeDemoButton" /> -->

    <b-dropdown-item
      link-class="d-flex align-items-center justify-content-between"
      @click="logout"
    >
      <div class="d-md-none d-blok ">
        <small class="user-name font-weight-bolder mb-1">
          {{ userData.fullName || userData.username }}
        </small>
      </div>

      <div class="">
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>{{ $t('message.Logout') }}</span>
      </div>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
// import axios from '@axios'
// import { updateOneClient } from '@/@core/queries/clients'
// import endpoints from '@/libs/endpoints'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: store.state?.userStore?.userData,
      clientData: JSON.parse(localStorage.getItem("clientData") || '{}'),
      // showChangeDemoButton: endpoints.app_id === process.env.VUE_APP_APP_ID_DEMO,
      avatarText,
    }
  },
  methods: {
    async logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      await useJwt.logout()

      // Remove tokens
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData and clientData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('clientData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    // changeDemo() {
    //   const newClientData = {
    //     ...this.clientData,
    //     compliance_calculation_fixed: !this.clientData.compliance_calculation_fixed,
    //     commitment_functionality: !this.clientData.commitment_functionality,
    //     new_confirmation_date_variable: !this.clientData.new_confirmation_date_variable,
    //   }

    //   localStorage.setItem('clientData', JSON.stringify(newClientData))

    //   axios
    //     .post("/graphql", {
    //       query: updateOneClient,
    //       variables: {
    //         query: { _id: this.userData.client.$oid },
    //         data: newClientData
    //       },
    //     })
    //     .then(async(response) => {
    //       if (response.data.errors) throw new Error(response.data.errors[0].message)

    //       // Refresh page so that components use the updated client variables
    //       location.reload()
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    // },
  },
}
</script>
