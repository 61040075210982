<template>
    <b-card class="text-center">
      <b-avatar
        class="mb-1"
        variant="light-danger"
        size="45"
      >
        <feather-icon
          size="21"
          icon="ClockIcon"
        />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ statistic }}
        </h2>
        <span>{{ commitmentFunctionality ? $t('message.overdue_m') : $t('message.overdue') }}</span>
      </div>
    </b-card>
  </template>
  
  <script>
  import { BCard, BAvatar } from 'bootstrap-vue'
  
  export default {
    components: {
      BCard,
      BAvatar,
    },
    props: {
      statistic: {
        type: [Number, String],
        required: true,
      },
      commitmentFunctionality: {
        type: Boolean,
        required: true
      }
    },
  }
  </script>